import React from 'react'
import { Link } from 'gatsby'

import SEO from '../components/scaffolding/seo'

const NotFoundPage = () => {
  return (
    <>
      <SEO title="Page Not Found" htmlClass="is-404" />
      <section className="content content-404">
        <div className="wrapper">
          <h1>Oh snap...</h1>
          <p>
            {`We couldn’t find that page. `}
            <Link to="/" className="link-404">
              Go home?
            </Link>
          </p>
        </div>
      </section>
    </>
  )
}

export default NotFoundPage
